<template>
  <div
    class="absolute shadow z-30 bottom-5 top-5 ml-5 rounded-xl left-5 grid-rows-layouts bg-white grid grid-cols-2 pl-6 pt-5 w-module"
    style="left: 68px"
  >
    <h1
      class="col-start-1 col-end-2 font-bold text-darkblue text-xl font-SourceSansPro"
    >
      {{ $t('geozones.library') }}
    </h1>
    <div class="flex justify-end col-start-2 items-center">
      <geozones-tools :measure="measure" @handle-measure="handleMeasure" />
      <img
        v-if="notRole"
        alt="history-icon"
        class="mr-5"
        src="@/assets/icons/history.svg"
        style="width: 18px; cursor: pointer"
        @click="$emit('show-history', { objects: 'geozones' })"
      />
      <i
        class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold pr-4"
        @click="$emit('close')"
      />
    </div>
    <div class="col-start-1 col-end-3 pb-3 pr-4 mt-6 flex justify-between">
      <skif-search
        v-model="filterString"
        class="w-full mr-4"
        :placeholder="$t('search')"
        @searching="searching"
      />

      <div v-if="stateIcon === 'select'">
        <el-tooltip
          :open-delay="700"
          effect="dark"
          :content="$t('delete')"
          placement="bottom"
          :offset="2"
          :visible-arrow="false"
        >
          <div
            class="h-8 focus_outline-none relative group"
            type="button"
            @click="deleteGeozones"
          >
            <svg
              class="group-hover_fill-fillTrash transition-colors ease-in-out duration-200"
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              fill="#91B4E7"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9 5H6v2h12V5h-3l-1-1h-4L9 5zm8 3H7v10a2 2 0 002 2h6a2 2 0 002-2V8z"
              />
            </svg>
            <skif-badge list="list" :value="selectedGeozones.length" />
          </div>
        </el-tooltip>
      </div>
      <skif-button
        v-if="stateIcon === 'select'"
        variant="normal"
        @click="cancel"
      >
        {{ $t('btn.cancel') }}
      </skif-button>
      <skif-button
        v-if="stateIcon === 'normal'"
        style="height: 35px"
        :disabled="disabledRole && role !== 'EDITOR'"
        @click="$emit('createGeozone')"
      >
        {{ $t('geozones.add') }}
      </skif-button>
    </div>
    <div @click="isFilter = true" class="cursor-pointer monitoring__filter">
      <FilterIcon class="monitoring__filter-icon" />
      <span class="monitoring__filter-title">
        {{ $t('monitoring.apply_filter') }}
        <span
          class="monitoring__filter-number"
          :class="{
            empty: !filters.length
          }"
        >
          {{ filters.length ? filters.length : 0 }}
        </span>
      </span>
    </div>
    <div class="col-start-1 col-end-3 pr-2 overflow-y-auto" ref="scroll-table">
      <table class="w-full table-fixed">
        <thead class="text-left table-border">
          <tr style="height: 42px">
            <th class="bg-white top-0 sticky z-10 w-6">
              <skif-checkbox
                v-model="isAllChecked"
                class="p-1"
                :indeterminate="isIndeterminate"
                :disabled="!filteredGeozones.length"
                @change="checkAllGeozones($event)"
                @click.native="(event) => event.stopPropagation()"
              />
            </th>
            <th class="bg-white top-0 sticky w-12 text-center">
              <span
                class="text-annotationColor font-semibold leading-10 text-sm font-SourceSansPro"
              >
                {{ $t('geozones.color') }}
              </span>
            </th>
            <th class="bg-white top-0 sticky mr-2">
              <span
                class="text-annotationColor font-semibold leading-10 text-sm font-SourceSansPro w-full"
              >
                {{ $t('geozones.name') }}
              </span>
            </th>
            <th class="bg-white top-0 sticky w-16">
              <span
                class="text-annotationColor font-semibold leading-10 text-sm font-SourceSansPro"
              >
                {{ $t('geozones.figure') }}
              </span>
            </th>
            <th class="bg-white top-0 sticky w-8 text-center">
              <span
                class="text-annotationColor font-semibold leading-10 text-sm font-SourceSansPro"
              >
                {{ $t('geozones.type') }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody class="" v-infinite-scroll="handlerInfiniteScroll">
          <tr
            v-for="geozone in filteredGeozones"
            class="cursor-pointer hover_bg-reallylightblue"
            :key="geozone.id"
            style="height: 43px"
            @click="$emit('updateGeozone', geozone)"
          >
            <td class="w-8">
              <skif-checkbox
                v-model="geozone.isInArray"
                class="p-1"
                @click.native="(event) => event.stopPropagation()"
                @change="changeChecked(geozone, $event)"
              />
            </td>
            <td>
              <span
                class="w-4 h-4 rounded-full block mx-auto"
                :style="{
                  backgroundColor:
                    geozone.color.key === '#1BB934'
                      ? '#4ABC96'
                      : geozone.color.key === '#1585D8'
                      ? '#2F80ED'
                      : geozone.color.key
                }"
              ></span>
            </td>
            <td class="truncate pt-2 m-2" style="padding-right: 20px">
              <el-tooltip
                :open-delay="700"
                effect="dark"
                :content="`${geozone.name} ${$t(
                  'geozones.square'
                )} ${getGeoArea(geozone)}`"
                placement="bottom"
                :offset="0"
                :visible-arrow="false"
              >
                <div
                  class="flex flex-col items-start truncate leading-normal mb-2 pr-2"
                >
                  <span
                    class="font-bold text-sm transition-colors duration-300"
                    :class="
                      geozone.isInArray ? 'text-headerText' : 'text-darkblue'
                    "
                  >
                    {{ geozone.name }}
                  </span>
                  <span class="area text-xs transition-colors duration-300">
                    {{ $t('geozones.square') }} {{ getGeoArea(geozone) }}
                  </span>
                </div>
              </el-tooltip>
            </td>
            <td>
              <span
                class="font-bold leading-10 text-sm transition-colors duration-300"
                :class="geozone.isInArray ? 'text-headerText' : 'text-grey'"
              >
                {{ geozone.type.value }}
              </span>
            </td>
            <td class="text-center">
              <img :src="`/static/geozones/${geozone.icon.key}.svg`" alt="" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <transition name="slide-fade" mode="out-in">
      <GeoFilter
        v-if="isFilter"
        @close="isFilter = false"
        @applyFilters="applyFilters"
      />
    </transition>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import geozonesMixin from '@/mixins/geozones.js'
import infiniteScroll from 'vue-infinite-scroll'
import GeozonesTools from '@/components/geozones/tools.vue'
import GeoFilter from '@/components/geozones/utils/filter.vue'
import FilterIcon from '@/components/monitoring/assets/FilterIcon.vue'

export default {
  name: 'ListGeozones',
  components: {
    GeozonesTools,
    GeoFilter,
    FilterIcon
  },
  directives: { infiniteScroll },
  mixins: [geozonesMixin],
  props: {
    map: {
      type: Object,
      default: () => {}
    },
    isGeo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFilter: false,
      selectedGeozones: [],
      stateIcon: 'normal',
      isDeleting: false,
      isCreating: false,
      isUpdating: false,
      filterString: '',
      isAllChecked: false,
      isIndeterminate: false,
      cellHeight: 43,
      viewportHeight: 0,
      totalHeight: 0,
      tolerance: 5,
      toleranceHeight: 43 * 5,
      bufferHeight: 0,
      firstEl: 0,
      lastEl: 0,
      topPaddingHeight: 0,
      bottomPaddingHeight: 0,
      bufferedItems: 0,
      count: 20,
      measure: 'm',
      filterLength: 0,
      filters: []
    }
  },

  computed: {
    hasFilters() {
      return (
        this.filterLength &&
        this.currentFilter.length &&
        this.currentFilter[0].values !== ''
      )
    },
    filterGeozones() {
      return this.geozones
        .filter((geozone) => {
          return geozone.name
            .toLowerCase()
            .includes(this.filterString.toLowerCase())
        })
        .slice(0, this.count)
    },
    geozonesIds() {
      return this.filteredGeozones.map((k) => k.id)
    },
    visibleItems: {
      get() {
        return this.filteredGeozones
      },
      set() {
        return this.filteredGeozones.slice(this.firstEl, this.lastEl)
      }
      // return this.filteredGeozones.slice(this.firstEl, this.lastEl)
    },
    geozonesItems() {
      return this.geozones.slice(0, this.count)
    },
    ...mapGetters('login', [
      'role',
      'changingCompany',
      'disabledRole',
      'notRole',
      'me'
    ]),

    filteredGeozones() {
      const filtered = this.geozones.filter((geozone) => {
        // Local filtering based on the filterString
        const nameMatch = geozone.name
          .toLowerCase()
          .includes(this.filterString.toLowerCase())
        return nameMatch
      })
      if (this.filters.length === 0) {
        return filtered
      }
      const currentMeasurementUnit = localStorage.getItem('measureGeo')

      // Helper function to convert area to the current measurement unit
      const convertArea = (area) => {
        if (currentMeasurementUnit === 'km') {
          return area / 1000000
        }
        if (currentMeasurementUnit === 'he') {
          return area / 10000
        }
        return area
      }

      // additionall filter
      return filtered.filter((geozone) => {
        return this.filters.every((filter) => {
          switch (filter.field) {
            case 'name':
              return geozone.name
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            case 'color':
              return geozone.color.key === filter.value

            case 'min_area':
              const minArea = Number(filter.value)
              return !isNaN(minArea) && convertArea(geozone.area) >= minArea

            case 'max_area':
              const maxArea = Number(filter.value)
              return !isNaN(maxArea) && convertArea(geozone.area) <= maxArea

            case 'form':
              return geozone.type.key === filter.value

            default:
              return true
          }
        })
      })
    },

    ...mapState('geozones', {
      geozones: (state) => state.geozones,
      recoveryId: (state) => state.recoveryId
    })
  },

  watch: {
    changingCompany() {
      this.cancel()
    },
    // eslint-disable-next-line func-names
    'filteredGeozones.length': function (val) {
      if (val < this.firstEl) {
        this.firstEl = 0
      }
      this.topPaddingHeight = Math.max(this.firstEl * this.cellHeight, 0)
      this.totalHeight = val * this.cellHeight
      this.bottomPaddingHeight = Math.max(
        this.totalHeight - this.topPaddingHeight - this.bufferHeight,
        0
      )
      //
      const valIds = this.selectedGeozones.map((k) => k.id)
      this.isAllChecked =
        !!this.geozonesIds.length &&
        this.geozonesIds.every((id) => valIds.includes(id))
      this.isIndeterminate =
        !this.isAllChecked && this.geozonesIds.some((id) => valIds.includes(id))
    }
  },
  methods: {
    ...mapActions({
      setGeozonesDimensions: 'geozones/SET_GEOZONES_DIMENSIONS'
    }),

    applyFilters(filters) {
      this.isFilter = false
      this.filters = filters
    },
    getGeoArea(geozone) {
      let geo

      if (this.measure === 'm') {
        geo = `${`${Math.round(geozone.area)} ${this.$t('geozones.meter')}`}²`
      } else if (this.measure === 'km') {
        geo = `${`${Math.round(geozone.area) / 1000000} ${this.$t(
          'geozones.km'
        )}`}²`
      } else {
        geo = `${`${Math.round(geozone.area) / 10000} ${this.$t(
          'geozones.hectar'
        )}`}`
      }

      return geo
    },
    async handleMeasure(measure) {
      let measureUnit
      if (measure === 'm') {
        measureUnit = 'm'
      } else if (measure === 'km') {
        measureUnit = 'km'
      } else {
        measureUnit = 'he'
      }
      const properties = {
        geozone_dimensions: measureUnit
      }
      const myself = { ...this.me, properties }
      this.measure = measure
      await this.setGeozonesDimensions(myself)
      // this.$store.dispatch('geozones/SET_GEOZONES_DIMENSIONS', myself)
    },
    searching(val) {
      this.filterString = val
    },
    closeDeletedNotification() {
      setTimeout(() => {
        this.$notify.closeAll()
      }, 10000)
    },

    handlerInfiniteScroll() {
      this.handlerScroll()
    },

    handlerScroll() {
      const el = document.querySelector('.overflow-y-auto')
      if (
        Math.ceil(el.scrollTop + el.clientHeight) >= el.scrollHeight &&
        this.geozones.length >= this.count
      ) {
        if (this.timeout) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.count += 10
        }, 200)
      }
      // this.firstEl = Math.floor(
      //   Math.max(event.target.scrollTop - this.toleranceHeight, 0) /
      //     this.cellHeight
      // )
      // this.lastEl = this.firstEl + this.bufferedItems
      // //it was v-for(geozone in visibleItems)
      // this.visibleItems = this.filteredGeozones.slice(this.firstEl, this.lastEl)
      // this.topPaddingHeight = Math.max(this.firstEl * this.cellHeight, 0)
      // this.bottomPaddingHeight = Math.max(
      //   this.totalHeight - this.topPaddingHeight - this.bufferHeight,
      //   0
      // )
    },

    changeChecked(geozone, $event) {
      const isChecked = $event.target.checked
      this.selectedGeozones = isChecked
        ? [...this.selectedGeozones, geozone]
        : this.selectedGeozones.filter((geo) => geo.id !== geozone.id)

      const selectedLength = this.selectedGeozones.length
      if (selectedLength === this.filteredGeozones.length) {
        this.isAllChecked = true
        this.isIndeterminate = false
        this.stateIcon = 'select'
      } else if (selectedLength === 0) {
        this.isAllChecked = false
        this.isIndeterminate = false
        this.stateIcon = 'normal'
      } else {
        this.isAllChecked = false
        this.isIndeterminate = true
        this.stateIcon = 'select'
      }
    },

    checkAllGeozones($event) {
      if ($event.target.checked) {
        this.selectedGeozones = this.filteredGeozones.map((geozone) => {
          geozone.isInArray = true
          return geozone
        })
        this.isAllChecked = true
        this.isIndeterminate = false
        this.stateIcon = 'select'
      } else {
        this.selectedGeozones = this.filteredGeozones.map((geozone) => {
          geozone.isInArray = false
          return geozone
        })
        this.selectedGeozones = []
        this.isAllChecked = false
        this.isIndeterminate = false
        this.stateIcon = 'normal'
      }
    },

    recoverItems(items) {
      const recoveryObject = {
        id: this.recoveryId,
        itemsDeleted: items
      }
      this.$store.dispatch('geozones/RECOVER_TRAILERS', recoveryObject)
      this.$notify.closeAll()
    },

    deleteGeozones() {
      const someUnits = this.filteredGeozones.filter((el) =>
        this.selectedGeozones.some((item) => el.id === item.id)
      )
      this.$notify({
        dangerouslyUseHTMLString: true,
        message: `<div class='confirmation-container ' >
                      <span class="" > ${
                        this.selectedGeozones.length <= 1
                          ? this.$t('one_element_deleted')
                          : `${this.selectedGeozones.length} ${this.$t(
                              'already_deleted_elements'
                            )}`
                      } </span>
                      <span id='confirm' class='cursor-pointer cancelConfirm text-annotationColor'>${this.$t(
                        'cancel'
                      )}</span>
                    </div>`,
        duration: 10000,
        position: 'bottom-left',
        customClass: 'confirmation '
      })
      this.closeDeletedNotification()
      const confirmation = document.getElementById('confirm')
      const self = this
      confirmation.addEventListener(
        'click',
        function () {
          self.recoverItems(someUnits)
        },
        false
      )
      this.$store
        .dispatch('geozones/DELETE_GEOZONE', this.selectedGeozones)
        .then((_) => {
          this.cancel()
        })
      // this.map.removeLayer(grayscale)
    },

    cancel() {
      this.filterString = ''
      this.stateIcon = 'normal'
      this.selectedGeozones = []
    }
  },
  mounted() {
    this.totalHeight = this.filteredGeozones.length * this.cellHeight
    this.viewportHeight = this.$refs['scroll-table'].clientHeight - 42
    this.bufferHeight = this.viewportHeight + 2 * this.toleranceHeight
    this.bottomPaddingHeight = Math.max(this.totalHeight - this.bufferHeight, 0)
    this.bufferedItems =
      Math.floor(this.viewportHeight / this.cellHeight) + 2 * this.tolerance

    this.lastEl = this.bufferedItems
    this.visibleItems = this.filteredGeozones.slice(this.firstEl, this.lastEl)
    if (this.me.properties && this.me.properties.geozone_dimensions) {
      this.measure = this.me.properties.geozone_dimensions
    }
    const savedFilters = localStorage.getItem('savedFilters')
    if (savedFilters) {
      this.filters = JSON.parse(savedFilters)
      this.applyFilters(this.filters)
    }
  },
  beforeDestroy() {
    this.selectedGeozones = this.filteredGeozones.map((geozone) => {
      geozone.isInArray = false
      return geozone
    })
  }
}
</script>

<style lang="stylus" scoped>
.grid-rows-layouts{
  grid-template-rows: auto auto auto 1fr;
}
.slide-fade-enter-active
  transition: all 0.4s ease;

.slide-fade-leave-active
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  transform: translateY(10px);
  opacity: 0;
.square {
  font-size: 7px;
  margin-bottom: 4px;
}
.geozone-name {
  line-height:2
}
.text-grey{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #BDBDBD;
}
.area{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: #C4C4C4;
  margin-top:4px;
}
.geozones {
  // z-index 0
  width: 596px;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.16);
  transform: none;
}
.empty
  background-color #BEC7D3
  margin-left 10px
  color : #ffffff
  font-size 14px
  padding 0 5px
  border-radius 50%

  &__unit-name
    width 265px
    white-space nowrap
    overflow hidden
    text-overflow ellipsis
    margin-left 12px
.color-cell {
  .cell {
    span {
      width: 20px;
      height: 20px;
      display: inline-block;
      border-radius: 50%;
    }
  }
}

.select-editor {
  display: flex;
  justify-content: space-between;

  i {
    font-size: 18px;

    &:hover {
      cursor: pointer;

      &.el-icon-edit {
        color: #008000;
      }

      &.el-icon-delete {
        color: #ff0000;
      }
    }
  }
}

.column-delete__btn {
  position: relative;
  z-index: 20;
}
</style>
<style lang="stylus">
.route-corridor {
  stroke: #999;
  stroke-opacity: 0.4;
}

.confirmation {
  width: 466px;
  background-color: #eff4fb;
  margin-left: 4.5rem;


  .el-notification__group {
    margin-right: 30px;
    width: 95%;

    .el-notification__content {
      width: 95%;

      p {
        .confirmation-container {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .el-icon-close {
      margin-top: 3px;

      &::before {
        font-weight: bolder;
        font-size: 18px;
      }
    }
  }
}
.table-border{
  border-bottom: 1px solid #C9D6DF
}
.monitoring__filter
      width: 103%
      display: flex
      align-items: center
      justify-content: flex-start
      &-icon
        margin-right 10px
      &-title
        color #446CA7
        font-size: 16px
        font-family: 'lato'
        font-weight: 600
      &-number
        margin-left 10px
        color : #ffffff
        font-size 14px
        background-color: #446CA7
        padding 0 5px
        border-radius 50%
</style>
